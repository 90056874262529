import axios from "axios";

const ingeoService = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + "/api/",
  // baseURL: "http://localhost/api/",
  timeout: 15000,
  headers: { "Content-Type": "application/json" },
});

export default ingeoService;
