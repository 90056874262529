var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-stats" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-5 col-md-4" },
          [
            _vm._t("header", [
              _c(
                "div",
                {
                  staticClass: "icon-big text-center",
                  class: "text-" + _vm.type
                },
                [_c("i", { class: _vm.icon })]
              )
            ])
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-7 col-md-8" },
          [
            _vm._t("content", [
              _c("div", { staticClass: "numbers" }, [
                _c("p", { staticClass: "card-category" }, [
                  _vm._v(_vm._s(_vm.smallTitle))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.title2
              ? _vm._t("content", [
                  _c("div", { staticClass: "numbers" }, [
                    _c("p", { staticClass: "card-category" }, [
                      _vm._v(_vm._s(_vm.smallTitle2))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.title2))
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.title3
              ? _vm._t("content", [
                  _c("div", { staticClass: "numbers" }, [
                    _c("p", { staticClass: "card-category" }, [
                      _vm._v(_vm._s(_vm.smallTitle3))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "card-title tittle3" }, [
                      _vm._v(_vm._s(_vm.title3))
                    ])
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }