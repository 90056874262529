import ingeoService from "./ingeo";
import { default as TokenService } from "./token";

const mensajeService = {};

mensajeService.mensajesVehiculo = function(patente, desde, hasta) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };

  // return ingeoService.get('vehiculos/' + patente + '/mensajes/' + desde + '/' + hasta,
  return ingeoService
    .get("vehiculos/" + patente + "/mensajes", { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

mensajeService.mensajes = function(limit) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };
  const requestParams = { limit: limit };

  return ingeoService
    .get("mensajes", { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

mensajeService.masMensajesPrevios = function(lastId, limit) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };
  const requestParams = { lastId: lastId, limit: limit };

  return ingeoService
    .get("mensajes-previos", { headers: requestHeaders, params: requestParams })
    .then(function(res) {
      return res;
    });
};

mensajeService.masMensajesNuevos = function(firstId) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };
  const requestParams = { firstId: firstId };

  return ingeoService
    .get("mensajes-nuevos", { headers: requestHeaders, params: requestParams })
    .then(function(res) {
      return res;
    });
};
mensajeService.setMensajeAck = function(msgId) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };
  const requestParams = { ackMsgId: msgId };

  return ingeoService
    .get("mensaje-ack", { headers: requestHeaders, params: requestParams })
    .then(function(res) {
      return res;
    });
};

export default mensajeService;
