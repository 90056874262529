import ingeoService from "./ingeo";
import { default as TokenService } from "./token";
import qs from "qs";

const crudService = {};

crudService.index = function(table) {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .get(table, { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

crudService.get = function(table, id) {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .get(table + "/" + id, { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

crudService.update = function(table, id, values) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: token
  };
  return ingeoService
    .put(table + "/" + id, qs.stringify(values), { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

crudService.create = function(table, id, values) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: token
  };
  return ingeoService
    .post(table, qs.stringify(values), { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

crudService.delete = function(table, id) {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .delete(table + "/" + id, { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};
export default crudService;
