import ingeoService from "./ingeo";
import { default as TokenService } from "./token";

const reporteService = {};

reporteService.resumenRecorridos = function(patente, desde, hasta) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };

  return ingeoService
    .get("vehiculos/" + patente + "/logrep/" + desde + "/" + hasta, {
      headers: requestHeaders
    })
    .then(function(res) {
      return res;
    });
};

reporteService.detalleRecorridos = function(patente, id) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };

  return ingeoService
    .get("vehiculos/" + patente + "/logrepitem/" + id, {
      headers: requestHeaders
    })
    .then(function(res) {
      return res;
    });
};

reporteService.historicoMapa = function(patente, fecha) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };

  return ingeoService
    .get("vehiculos/" + patente + "/logrepgps/" + fecha, {
      headers: requestHeaders
    })
    .then(function(res) {
      return res;
    });
};

export default reporteService;
