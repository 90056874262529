import Vue from "vue";

const x = process.env.VUE_APP_API_BASE_URL + "/api/";

const store = Vue.observable({
  moduleName: String,
  menuItems: []
});

const mutations = {
  setModuleName(payload) {
    store.moduleName = payload;
  },
  setMenuItems(payload) {
    store.menuItems = payload;
  }
};

export default { store, mutations };
