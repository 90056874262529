import ingeoService from "./ingeo";
import { default as TokenService } from "./token";
import qs from "qs";

const comandoService = {};

comandoService.index = function() {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .get("comando", { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

comandoService.get = function(id) {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .get("comando/" + id, { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

comandoService.update = function(id, values) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: token
  };
  return ingeoService
    .put("comando/" + id, qs.stringify(values), { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

comandoService.create = function(id, values) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: token
  };
  return ingeoService
    .post("comando", qs.stringify(values), { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

comandoService.delete = function(id) {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .delete("comando/" + id, { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};
export default comandoService;
