const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  saveTokenAsBearer(accessToken) {
    const token = "Bearer " + accessToken;
    localStorage.setItem(TOKEN_KEY, token);
  },
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  removeTokenAsync() {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(TOKEN_KEY);
        resolve(null);
      } catch (err) {
        reject(err);
      }
    });
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
};

export default TokenService;
