var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar navbar-expand-lg", class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm._t("toggle-button", [
            _c(
              "button",
              {
                staticClass: "navbar-toggler",
                attrs: {
                  "aria-controls": "navigation-index",
                  "aria-expanded": "true",
                  "aria-label": "Toggle navigation",
                  "data-toggle": "collapse",
                  type: "button"
                },
                on: { click: _vm.toggleMenu }
              },
              [
                _c("span", { staticClass: "navbar-toggler-bar navbar-kebab" }),
                _c("span", { staticClass: "navbar-toggler-bar navbar-kebab" }),
                _c("span", { staticClass: "navbar-toggler-bar navbar-kebab" })
              ]
            )
          ]),
          _vm._v(" "),
          _c("CollapseTransition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showNavbar,
                    expression: "showNavbar"
                  }
                ],
                staticClass:
                  "collapse navbar-collapse justify-content-end show",
                class: _vm.navbarMenuClasses,
                attrs: { id: "navigation" }
              },
              [
                _c(
                  "ul",
                  { staticClass: "navbar-nav" },
                  [_vm._t("navbar-menu")],
                  2
                )
              ]
            )
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }