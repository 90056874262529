// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Muli:400,300);"]);
exports.push([module.id, "@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css);"]);
exports.push([module.id, "@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);"]);
// Module
exports.push([module.id, "/* 1rem === 10px, but remain responsive to user font size settings */\n\n:root {\n  font-size: 62.5%;\n  --navbar-width: 23.6rem;\n  --navbar-top: 50px;\n}\n\n/* body {\n  font-family: Montserrat\", sans-serif;\n  font-size: 1.6rem;\n  background-color: #efefef;\n  margin: 0;\n} */\n\nbody {\n  color: #2c2c2c;\n  font-size: 14px;\n  font-family: \"Montserrat\", sans-serif;\n  -apple-system: \"Montserrat\", Helvetica;\n         system:  \"Montserrat\", Helvetica;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-font-smoothing: antialiased;\n  background-color: #efefef;\n  margin: 0;\n}", ""]);
// Exports
module.exports = exports;
