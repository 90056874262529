import ingeoService from "./ingeo";
import { default as TokenService } from "./token";

const perfilService = {};

perfilService.index = function() {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };

  return ingeoService
    .get("perfil", { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

export default perfilService;
