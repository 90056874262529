import ingeoService from "./ingeo";
import { default as TokenService } from "./token";

const authService = {};
authService.checkPermission = function(to) {
  const loggedIn = !!TokenService.getToken();
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );

  if (!isPublic && !loggedIn) {
    return "/login";
    // return {
    //   path: "/login",
    //   query: { redirect: to.fullPath }
    // };
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return "/menu";
  }
  return;
};

authService.hasPermission = function(to) {
  const loggedIn = !!TokenService.getToken();
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  if (!isPublic && !loggedIn) {
    return false;
  }
  return true;
};

authService.login = function(login, password) {
  const data = {
    login: login,
    password: password
  };
  return ingeoService.post("auth/login", data);
  //   return ingeoService.post("auth/login", data).then(function (res) {
  //     TokenService.saveTokenAsBearer(res.data.access_token);
  //     return res;
  //   });
};

authService.userInfo = function() {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };

  return ingeoService
    .get("auth/user", { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

authService.logout = () => {
  // Remueve el token
  TokenService.removeToken();
  TokenService.removeRefreshToken();
};
export default authService;
