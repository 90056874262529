var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate",
      class: [
        _vm.switchClass,
        { "bootstrap-switch-disabled": _vm.disabled },
        { "bootstrap-switch-readonly": _vm.readonly },
        { "bootstrap-switch-indeterminate": _vm.indeterminate }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "bootstrap-switch-container",
          on: {
            click: function($event) {
              return _vm.triggerToggle()
            }
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "bootstrap-switch-handle-on ",
              class: "bootstrap-switch-" + _vm.type
            },
            [
              _vm._t("on", [
                _vm._v("\n          " + _vm._s(_vm.onText) + "\n      ")
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("span", { staticClass: "bootstrap-switch-label" }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "bootstrap-switch-handle-off",
              class: "bootstrap-switch-" + _vm.type
            },
            [
              _vm._t("off", [
                _vm._v("\n          " + _vm._s(_vm.offText) + "\n      ")
              ])
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }