import ingeoService from "./ingeo";
import { default as TokenService } from "./token";
import qs from "qs";

const userService = {};

userService.index = function() {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .get("user", { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

userService.get = function(id) {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .get("user/" + id, { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

userService.update = function(id, values) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: token
  };
  return ingeoService
    .put("user/" + id, qs.stringify(values), { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

userService.create = function(id, values) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: token
  };
  return ingeoService
    .post("user", qs.stringify(values), { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

userService.delete = function(id) {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };
  return ingeoService
    .delete("user/" + id, { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};
export default userService;
