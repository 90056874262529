import "./set-public-path";
import "./global.css";
import "./assets/css/vue-multiselect.min.css";

// import "./assets/sass/paper-dashboard.scss";
// import "./assets/sass/demo.scss";

export { default as PCheckbox } from "./UIComponents/Inputs/Checkbox.vue";
export { default as PSwitch } from "./UIComponents/Switch.vue";
export { default as PButton } from "./UIComponents/Button.vue";
export { default as Card } from "./UIComponents/Cards/Card.vue";
export { default as StatsCard } from "./UIComponents/Cards/StatsCard.vue";
export { default as Checkbox } from "./UIComponents/Inputs/Checkbox.vue";
export { default as FgInput } from "./UIComponents/Inputs/formGroupInput.vue";
export { default as Navbar } from "./UIComponents/Navbar/Navbar.vue";
export { default as PProgres } from "./UIComponents/Progress.vue";
export { default as Tabs } from "./UIComponents/Tabs/Tabs.vue";
export { default as TabPane } from "./UIComponents/Tabs/Tab.vue";

export { default as PageHeader } from "./component-library/page-header.vue";
export { default as store, default as mutations } from "./store.js";

export { default as ingeoService } from "./services/ingeo.js";
export { default as TokenService } from "./services/token.js";
export { default as authService } from "./services/auth.js";
export { default as mensajeService } from "./services/mensaje.js";
export { default as vehiculoService } from "./services/vehiculo.js";
export { default as vehiculosService } from "./services/vehiculos.js";
export { default as choferService } from "./services/chofer.js";
export { default as trackerService } from "./services/tracker.js";
export { default as grupoService } from "./services/grupo.js";
export { default as grupoVehiculoService } from "./services/grupo-vehiculo.js";
export { default as grupoUsuarioService } from "./services/grupo-usuario.js";
export { default as reporteService } from "./services/reporte.js";
export { default as perfilService } from "./services/perfil.js";
export { default as mantenimiemtoPerfilService } from "./services/mantenimiento-perfil.js";
export { default as vehiculoTipoService } from "./services/vehiculo-tipo.js";
export { default as crudService } from "./services/crud.js";
export { default as userService } from "./services/user.js";
export { default as comandoService } from "./services/comando.js";
