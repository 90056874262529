import ingeoService from "./ingeo";
import { default as TokenService } from "./token";

const vehiculosService = {};

vehiculosService.index = function() {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };

  return ingeoService
    .get("vehiculos", { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

vehiculosService.route = function(pat, limit) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };
  let requestParams = {};
  if (limit) {
    requestParams = { limit: limit };
  }

  return ingeoService
    .get("vehiculos/" + pat + "/coordenadas", {
      headers: requestHeaders,
      params: requestParams
    })
    .then(function(res) {
      return res;
    });
};

vehiculosService.vbat = function(pat) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };
  const requestParams = { limit: 1, var: "VBATPRN" };

  return ingeoService
    .get("vehiculos/" + pat + "/feeds", {
      headers: requestHeaders,
      params: requestParams
    })
    .then(function(res) {
      return res;
    });
};

vehiculosService.digitalInput = function(pat, inputNumber) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };
  const requestParams = { limit: 1, var: "INP00" + inputNumber };

  return ingeoService
    .get("vehiculos/" + pat + "/feeds", {
      headers: requestHeaders,
      params: requestParams
    })
    .then(function(res) {
      return res;
    });
};

vehiculosService.feeds = function(pat) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };

  return ingeoService
    .get("vehiculos/" + pat + "/feeds", {
      headers: requestHeaders
    })
    .then(function(res) {
      return res;
    });
};

vehiculosService.analogInput = function(pat, inputNumber, limit) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };
  const requestParams = { limit: limit, var: "AAG00" + inputNumber };

  return ingeoService
    .get("vehiculos/" + pat + "/feeds", {
      headers: requestHeaders,
      params: requestParams
    })
    .then(function(res) {
      return res;
    });
};

export default vehiculosService;
