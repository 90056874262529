import ingeoService from "./ingeo";
import { default as TokenService } from "./token";

const vehiculoService = {};

vehiculoService.index = function() {
  const token = TokenService.getToken();
  const requestHeaders = { Authorization: token };

  return ingeoService
    .get("vehiculo", { headers: requestHeaders })
    .then(function(res) {
      return res;
    });
};

vehiculoService.get = function(id) {
  const token = TokenService.getToken();
  const requestHeaders = {
    "content-type": "application/json",
    Authorization: token
  };

  return ingeoService
    .get("vehiculo/" + id, {
      headers: requestHeaders
    })
    .then(function(res) {
      return res;
    });
};

export default vehiculoService;
